import styled from 'styled-components'
import { Box, Flex, Text, Heading, Link, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import Image from 'next/image'
import { ReactNode } from 'react';
import { BallWithNumber, MatchExampleA, MatchExampleB, PoolAllocationChart } from '../svgs'
import step1Image from '../Assests/Images/pass.png'
import step2Image from '../Assests/Images/time.png' // Example dynamic image
import step3Image from '../Assests/Images/prize.png' // Example dynamic image

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBorder};
  height: 1px;
  margin: 40px 0;
  width: 100%;
`

const BulletList = styled.ul`
  list-style-type: none;
  margin-left: 8px;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
  li::before {
    content: '•';
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.textSubtle};
  }
  li::marker {
    font-size: 12px;
  }
`

const StepContainer = styled(Flex)`
  gap: 24px;
  width: 100%;
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
`
const StyledWrapStepCard = styled(Box)`
  position: relative;
`
const StyledStepCard = styled(Box)`
  display: flex;
  align-self: baseline;
  position: relative;
  padding: 1px 1px 3px 1px;
  border-radius: 0 10px;
  position: relative;
`

const StepCardInner = styled(Box)`
  width: 100%;
  padding: 24px;
  background: ${({ theme }) => theme.colors.cardbackground};
  border-radius : 10px;
  filter: drop-shadow(2px 4px 6px #488ddba2);
  .stepsec{
  display : flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:6px;
}
 
  .stepimgdiv {
    position: relative;  // Ensure that the ::after pseudo-element is positioned relative to this element
  }
  .stepimgdiv::after {
    content: "";
    position: absolute;
    width: 70px;
    height: 100px;
    background: #66AB52;
    left: 0;
    top: 0;
    filter: blur(50px);
    z-index: -1;
  }
    .stepimg{
    
transition: all 0.8s ease-in-out;    }
.stepimg:hover {
  transform: rotateY(360deg);  /* Apply rotation on hover */
}

`
type Step = { title: ReactNode; subtitle: string; label: string, image: string }

const StepCard: React.FC<React.PropsWithChildren<{ step: Step }>> = ({ step }) => {
  return (
    <StyledWrapStepCard>
      <div className="bg uwu" />
      <div className="bg" />
      <StyledStepCard width="100%">
        <StepCardInner height={['auto']}>
          {/* <Text mb="4px" fontSize="20px" color='#4b84f3' bold textAlign="left" textTransform="uppercase" className="steplist">
            {step.label}
          </Text> */}
          <div className="stepsec">
            <Heading mb="5px" fontSize="10px" scale="lg" color="secondary" style={{ fontSize: '15px !important' }}>
              {step.title}
            </Heading>
            <div className="stepimgdiv">

            <Image className="stepimg" src={step.image} width={60} height={60} alt="step image" />
            </div>
          </div>
          <Text color="textSubtle">{step.subtitle}</Text>
        </StepCardInner>
      </StyledStepCard>
    </StyledWrapStepCard>
  )
}

const BallsContainer = styled(Flex)`
  // padding-left: 28px;
  display: flex;
  justify-content : center;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.xs} {
    // padding-left: 36px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    gap: 8px;
    // padding-left: 40px;
  }
`

const InlineLink = styled(Link)`
  display: inline;
`

const ExampleBalls = () => {
  const { isDesktop } = useMatchBreakpoints()
  // const ballSize = isDesktop ? '24px' : '32px'
  const fontSize = isDesktop ? '14px' : '16px';
  return (
    <BallsContainer className='Criteriaballs'>
      <BallWithNumber fontSize={fontSize} color="yellow" number="9" />
      <BallWithNumber fontSize={fontSize} color="green" number="1" />
      <BallWithNumber fontSize={fontSize} color="aqua" number="3" />
      <BallWithNumber fontSize={fontSize} color="teal" number="6" />
      <BallWithNumber fontSize={fontSize} color="lilac" number="6" />
      <BallWithNumber fontSize={fontSize} color="pink" number="2" />
    </BallsContainer>
  )
}

const MatchExampleContainer = styled(Flex)`
  height: 100%;
  flex-direction: column;
`

const MatchExampleCard = () => {
  const { isDark } = useTheme()
  const { isXs } = useMatchBreakpoints()
  const { t } = useTranslation()
  const exampleWidth = isXs ? '210px' : '258px'
  return (
    <StyledStepCard width={['280px', '330px', '330px']}>
      <StepCardInner height="auto">
        <MatchExampleContainer>
          <ExampleBalls />
          <Flex>
            <Text lineHeight="72px" textAlign="right" color="secondary" bold mr="20px">
              {t('A')}
            </Text>
            <MatchExampleA width={exampleWidth} height="46px" isDark={isDark} />
          </Flex>
          <Flex>
            <Text lineHeight="72px" textAlign="right" color="secondary" bold mr="20px">
              {t('B')}
            </Text>
            <MatchExampleB width={exampleWidth} height="46px" isDark={isDark} />
          </Flex>
        </MatchExampleContainer>
      </StepCardInner>
    </StyledStepCard>
  )
}

const AllocationGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-auto-rows: max-content;
  row-gap: 4px;
`

const AllocationColorCircle = styled.div<{ color: string }>`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-color: ${({ color }) => color};
`

const AllocationMatch: React.FC<React.PropsWithChildren<{ color: string; text: string }>> = ({ color, text }) => {
  return (
    <Flex alignItems="center">
      <AllocationColorCircle color={color} />
      <Text color="textSubtle">{text}</Text>
    </Flex>
  )
}

const PoolAllocations = () => {
  const { t } = useTranslation()
  return (
    <StyledStepCard width={['280px', '330px', '380px']}>
      <StepCardInner height="auto">
        <Flex mb="32px" justifyContent="center">
          <PoolAllocationChart width="100px" height="100px" />
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontSize="12px" color="secondary" bold textTransform="uppercase">
            {t('Digits matched')}
          </Text>
          <Text fontSize="12px" color="secondary" bold textAlign="right" textTransform="uppercase">
            {t('Prize pool allocation')}
          </Text>
        </Flex>
        <AllocationGrid>
          <AllocationMatch color="#FFE362" text={t('Matches first %digits%', { digits: 1 })} />
          <Text textAlign="right" bold>
            2%
          </Text>
          <AllocationMatch color="#85C54E" text={t('Matches first %digits%', { digits: 2 })} />
          <Text textAlign="right" bold>
            3%
          </Text>
          <AllocationMatch color="#028E75" text={t('Matches first %digits%', { digits: 3 })} />
          <Text textAlign="right" bold>
            5%
          </Text>
          <AllocationMatch color="#36E8F5" text={t('Matches first %digits%', { digits: 4 })} />
          <Text textAlign="right" bold>
            10%
          </Text>
          <AllocationMatch color="#A881FC" text={t('Matches first %digits%', { digits: 5 })} />
          <Text textAlign="right" bold>
            20%
          </Text>
          <AllocationMatch color="#D750B2" text={t('Matches all 6')} />
          <Text textAlign="right" bold>
            40%
          </Text>
          <AllocationMatch color="#BDC2C4" text={t('Burn Pool')} />
          <Text textAlign="right" bold>
            20%
          </Text>
        </AllocationGrid>
      </StepCardInner>
    </StyledStepCard>
  )
}

const GappedFlex = styled(Flex)`
  gap: 24px;
`

const HowToPlay: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation()

  const steps: Step[] = [
    {
      label: t('step %number%', { number: 1 }),
      title: (
        <>{t('Buy')} <br /> {t('Tickets')}</>
      ),
      subtitle: t('Prices are set when the round starts, equal to 1 EXBIT per ticket.'),
      image: step1Image.src,
    },
    {
      label: t('step %number%', { number: 2 }),
      title: (<>{t('Wait for')} <br /> {t('the Draw')}</>),
      subtitle: t('There is one draw every day alternating between 0 AM UTC and 12 PM UTC.'),
      image: step2Image.src,
    },
    {
      label: t('step %number%', { number: 3 }),
      title: (<>{t('Check for')} <br /> {t('Prizes')}</>),
      subtitle: t("Once the round's over, come back to the page and check to see if you've won!"),
      image: step3Image.src,
    },
  ];
  return (
    <Box width="100%">
      <Flex mb="40px" alignItems="center" flexDirection="column">
        <Heading mb="24px" scale="xl" color="#c7b118">
          {t('How to Play')}
        </Heading>
        <Text textAlign="center">
          {t(
            'If the digits on your tickets match the winning numbers in the correct order, you win a portion of the prize pool.',
          )}
        </Text>
        <Text>{t('Simple!')}</Text>
      </Flex>
      <StepContainer>
        {steps.map((step) => (
          <StepCard key={step.label} step={step} />
        ))}
      </StepContainer>
      <Divider />
      <GappedFlex flexDirection={['column', 'column', 'column', 'row']}>
        <Flex flex="2" flexDirection="column">
          <Heading mb="24px" scale="lg" color="#c7b118">
            {t('Winning Criteria')}
          </Heading>
          <Heading mb="24px" scale="md">
            {t('The digits on your ticket must match in the correct order to win.')}
          </Heading>
          <Text mb="16px" color="textSubtle">
            {t("Here's an example lottery draw, with two tickets, A and B.")}
          </Text>
          <BulletList>
            <li>
              <Text display="inline" color="textSubtle">
                {t(
                  'Ticket A: The first 3 digits and the last 2 digits match, but the 4th digit is wrong, so this ticket only wins a “Match first 3” prize.',
                )}
              </Text>
            </li>
            <li>
              <Text display="inline" color="textSubtle">
                {t(
                  "Ticket B: Even though the last 5 digits match, the first digit is wrong, so this ticket doesn't win a prize.",
                )}
              </Text>
            </li>
          </BulletList>
          <Text mt="16px" color="textSubtle">
            {t(
              "Prize brackets don't 'stack': if you match the first 3 digits in order, you'll only win prizes from the 'Match 3' bracket, and not from 'Match 1' and 'Match 2'.",
            )}
          </Text>
        </Flex>
        <Flex flex="1" justifyContent="center">
          <MatchExampleCard />
        </Flex>
      </GappedFlex>
      {/* <Divider /> */}
      {/* <GappedFlex flexDirection={['column', 'column', 'column', 'row']}>
        <Flex flex="2" flexDirection="column">
          <Heading mb="24px" scale="lg" color="#c7b118">
            {t('Prize Funds')}
          </Heading>
          <Text color="textSubtle">{t('The prizes for each lottery round come from these sources:')}</Text>
          <Heading my="16px" scale="md">
            {t('Ticket Purchases')}
          </Heading>
          <BulletList>
            <li>
              <Text display="inline" color="textSubtle">
                {t(
                  '100% of the EXBIT paid by people buying tickets that round goes back to the dealer contract, which in turn gives back EXBIT when winning amount is declared.',
                )}
              </Text>
            </li>
          </BulletList>
          <Heading my="16px" scale="md">
            {t('Benefits for EXBIT NFT Stakers')}
          </Heading>
          <BulletList>
            <li>
              <Text display="inline" color="textSubtle">
                {t(
                  'After every round, if nobody wins in one of the prize brackets, the unclaimed EXBIT for that is divided into 2 parts on the dealer contract, which burns 20% and distribute the remaining 80% to the EXBIT NFT staking pool.',
                )}
                You can check more on the staking website:{' '}
                <InlineLink href="https://stake-nft.hashfair.io">{t('EXBIT NFT Staking')}</InlineLink>
              </Text>
            </li>
          </BulletList>
        </Flex>
        <Flex flex="1" justifyContent="center">
          <PoolAllocations />
        </Flex>
      </GappedFlex> */}
      <Divider />
    </Box>
  )
}

export default HowToPlay
