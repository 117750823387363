import React from 'react';
import styled from 'styled-components';
import { Text, Flex } from '@pancakeswap/uikit';

// Ball text wrapper styling
export const BallTextWrapper = styled.div`
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 25px;
`;

// Ball text styling
export const BallText = styled(Text)<{ rotationTransform?: number }>`
  color: #F4EEFF;
  font-size: inherit;
  transform: rotate(${({ rotationTransform }) => rotationTransform || 0}deg);
`;

// Define BallColor types
export type BallColor = 'pink' | 'lilac' | 'teal' | 'aqua' | 'green' | 'yellow';

interface BallWithNumberProps {
  color: BallColor;
  number: string;
  size?: string;
  fontSize?: string;
  rotationTransform?: number;
  borderWidth?: string;
  borderColor?: string;
}

// Component for rendering a colored ball
export const BallWithNumber: React.FC<BallWithNumberProps> = ({
  color,
  number,
  size = '32px',
  fontSize = '25px',
  rotationTransform,
  borderWidth = '.5em',  // Default border width
  borderColor = '#fff', 
  
}) => {
  const colorMap: Record<BallColor, string> = {
    pink: '#FF1493 ', // Pink radial gradient
    lilac: '#8A2BE2', // Lilac radial gradient
    teal: '#20B2AA ', // Teal radial gradient
    aqua: 'darkblue', // Aqua radial gradient
    green: '#228B22', // Green radial gradient
    yellow: '#B8860B', // Yellow radial gradient
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      position="relative"
      mx="2px"
      className="ballwrap ball-with-number"
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        background: colorMap[color], // Apply gradient background
        border: `${borderWidth} dashed  ${borderColor}`, // Apply border
      }}
    >
      <BallTextWrapper>
        <BallText rotationTransform={rotationTransform} style={{ fontSize }}>
          {number}
        </BallText>
      </BallTextWrapper>
    </Flex>
  );
};

// Mapping numbers to BallColor
const numberToColor: Record<string, BallColor> = {
  '0': 'pink',
  '1': 'lilac',
  '2': 'teal',
  '3': 'aqua',
  '4': 'green',
  '5': 'yellow',
  '6': 'pink',
  '7': 'lilac',
  '8': 'teal',
  '9': 'aqua',
};

interface WinningNumbersProps {
  number: string;
  size?: string;
  fontSize?: string;
  rotateText?: boolean;
  borderWidth?: string;
  borderColor?: string;
}

// Component for rendering a sequence of winning numbers
export const WinningNumbers: React.FC<WinningNumbersProps> = ({
  number,
  size = '64px',
  fontSize = '25px',
  rotateText,
  borderWidth,
  borderColor,
}) => {
  const numArray = number.split('');

  return (
    <Flex justifyContent="space-between">
      {numArray.map((num) => {
        const color = numberToColor[num] || 'pink'; // Default to 'pink' if undefined
        return (
          <BallWithNumber
            key={`ball-${num}-${Math.random()}`}
            color={color}
            number={num}
            size={size}
            fontSize={fontSize}
            rotationTransform={rotateText ? Math.random() * 30 - 15 : 0}
            borderWidth={borderWidth}
            borderColor={borderColor}
          />
        );
      })}
    </Flex>
  );
};

export default WinningNumbers;
