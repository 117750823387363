import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css/bundle';

const Lotterybannerslider = () => {
    const swiperRef = useRef(null);

    const images = [
        '/slider1.jpg',
        '/slider2.jpg',
        '/slider1.jpg',
    ];

    const handleMouseEnter = () => {
        if (swiperRef.current) {
            swiperRef.current.autoplay.stop();
        }
    };

    const handleMouseLeave = () => {
        if (swiperRef.current) {
            swiperRef.current.autoplay.start();
        }
    };

    return (
        <Swiper
            style={{ overflow: 'hidden' }}
            loop
            autoplay={{
                delay: 2000,
                disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            slidesPerView={1}
            spaceBetween={0}
            onSwiper={(swiper) => {
                swiperRef.current = swiper
                // swiper.autoplay.start()
                // return;
            }}
            breakpoints={{
                320: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 1,
                },
                1024: {
                    slidesPerView: 1,
                },
            }}
        >
            {images.map((image) => (
                <SwiperSlide
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '0 auto',
                        textAlign: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                    }}
                    key={image}
                >
                    <img
                        src={image}
                        alt={`Lottery banner ${image.split('/').pop()?.split('.')[0]}`}
                        style={{
                            width: '100%',
                            maxWidth: '1200px',
                            height: 'auto',
                            objectFit: 'cover',
                            margin: '50px',
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Lotterybannerslider;
